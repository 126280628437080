// Load Styles
import "../scss/main.scss";

// Load Bootstrap init
import { initBootstrap } from "./bootstrap.js";

// Loading bootstrap with optional features
initBootstrap({
  tooltip: false,
  popover: false,
  toasts: false,
});

const navToggle = document.getElementById("toggleNav");
const navWrapper = document.getElementById("navWrapper");

navToggle.addEventListener("click", (event) => {
  event.preventDefault();
  navWrapper.classList.toggle("d-none");
  navWrapper.classList.toggle("d-flex");
});
